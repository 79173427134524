<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12">
            <div class="card">
                <DataTable
                    dataKey="id"
                    responsiveLayout="scroll"
                    filterDisplay="menu"
                    :rows="15"
                    :paginator="true"
                    :lazy="true"
                    :value="foodies.data"
                    :totalRecords="foodies.total"
                    :rowHover="true"
                    :loading="loading"
                    @page="query.page = $event.page + 1"
                    >
                    <template #header>
                        <h2>Foodies</h2>

                        <div class="p-d-flex p-jc-between">
                            <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="query.search" placeholder="Nombre, email o ig..."/>
                        </span>
                            </div>
                        </div>

                        <h3>Filtros</h3>

                        <!-- Enabled Users Checkbox -->
                        <div class="field-checkbox p-mt-2">
                            <Checkbox id="enabledUsersFilter" v-model="query.enabled" :binary="true"/>
                            <label for="enabledUsersFilter" class="p-ml-2">
                                <div v-if="query.enabled">
                                    Usuarios habilitados (aplicado)
                                </div>

                                <div v-else-if="query.enabled === false">
                                    Usuarios Deshabilitados (aplicado)
                                </div>

                                <div v-else>
                                    Usuarios habilitados (no aplicado)
                                </div>
                            </label>
                        </div>

                        <!-- Ig Validated Users Checkbox -->
                        <div class="field-checkbox p-mt-2">
                            <Checkbox id="validatedIgUsersFilter" v-model="query.ig_validated" :binary="true"/>
                            <label for="validatedIgUsersFilter" class="p-ml-2">
                                <div v-if="query.ig_validated">
                                    Validados (instagram)
                                </div>

                                <div v-else-if="query.ig_validated === false">
                                    No Validados (instagram)
                                </div>

                                <div v-else>
                                    Validados (instagram) (no aplicado)
                                </div>
                            </label>
                        </div>

                        <!-- Deleted Users Checkbox -->
                        <div class="field-checkbox p-mt-2">
                            <Checkbox id="deletedUsersFilter" v-model="query.deleted" :binary="true"/>
                            <label for="deletedUsersFilter" class="p-ml-2">
                                <div v-if="query.deleted">
                                    Eliminados (aplicado)
                                </div>

                                <div v-else-if="query.deleted === false">
                                    No Eliminados (aplicado)
                                </div>

                                <div v-else>
                                    Eliminados (no aplicado)
                                </div>
                            </label>
                        </div>

                        <!-- Rejected Users Checkbox -->
                        <div class="field-checkbox p-mt-2">
                            <Checkbox id="rejectedUsersFilter" v-model="query.rejected" :binary="true"/>
                            <label for="rejectedUsersFilter" class="p-ml-2">
                                <div v-if="query.rejected">
                                    Rechazados (aplicado)
                                </div>

                                <div v-else-if="query.rejected === false">
                                    No rechazados (aplicado)
                                </div>

                                <div v-else>
                                    Rechazados (no aplicado)
                                </div>
                            </label>
                        </div>

                        <!-- Reset filters btn -->
                        <div class="p-col-3">
                            <Button label="Reset filters" class="p-button-sm" @click="resetQueryFilters"/>
                        </div>

                    </template>

                    <Column field="name" header="Nombre">
                        <template #body="{data}">
                            <div v-tooltip.top="`ID: ${data.id} (${timestampToLocal(data.created_at)})`">
                                <a href="#">
                                    {{ data.name }}
                                </a>

                            </div>
                        </template>
                    </Column>

                    <Column field="email" header="Email"></Column>

                    <Column field="ig_username" header="Instagram">
                        <template #body="{data}">
                            <div v-if="data.ig_username">
                                <a target="_blank" v-if="data.ig_username"
                                   :href="`https://instagram.com/${data.ig_username}`">
                                    @{{ data.ig_username }} <span v-if="data.user.ig_discoverable">(creador)</span>
                                </a>
                            </div>

                            <div v-else>
                                -
                            </div>
                        </template>
                    </Column>

                    <Column field="balance_e2" header="Balance">
                        <template #body="{data}">
                            <p @click="handleHistoric(data)" style="color: #2196f3; cursor: pointer;" v-tooltip.top="'ver historico'">
                                {{ `${(data?.balance_e2 / 100).toFixed(2)}€` }}
                            </p>
                        </template>
                    </Column>

                    <Column field="user_enabled" header="Habilitado?">
                        <template #body="{data}">
                            <div>
                                {{ data.user.enabled ? "Si" : "No" }}
                            </div>
                        </template>
                    </Column>

                    <Column field="is_premium" header="Premium">
                        <template #body="{data}">
                            <div>
                                {{ data.is_premium ? "Si" : "No" }}
                            </div>
                        </template>
                    </Column>

                    <Column field="user_status" header="Estado">
                        <template #body="{data}">

                            <div v-if="data.user.ig_validated_at !== null">
                                Validado (IG)
                            </div>

                            <div v-else-if="data.user.rejected">
                                Rechazado
                            </div>

                            <div v-else>
                                -
                            </div>
                        </template>
                    </Column>

                    <Column header="">
                        <template #body="{data}">
                            <Button type="button" icon="pi pi-cog" class="p-button-rounded p-button-outlined"
                                    @click="toggleActionMenu($event, data)" aria-haspopup="true"
                                    aria-controls="overlay_menu"/>
                            <Menu id="overlay_menu" ref="action_menu" :model="actionMenuItems" :popup="true"/>
                        </template>
                    </Column>

                    <template #empty>
                        No se encontraron foodies
                    </template>
                    <template #loading>
                        Cargando foodies
                    </template>
                </DataTable>
            </div>
        </div>
    </div>

    <Toast/>

    <div v-if="displayAssignInvModal">
        <Dialog header="Confirmar invitaciones" v-model:visible="displayAssignInvModal" :style="{width: '50vw'}"
                :modal="true">
            <form action="">
                <div class="p-field">
                    <div class="p-text-center">
                        <label for="invitations_qty" class="p-d-block p-mb-2">Cantidad de invitaciones</label>
                        <InputNumber ref="invitationsQtyInput" id="invitations_qty" v-model="invitationsQuantity"
                                     mode="decimal"
                                     :useGrouping="false"
                                     :min="1"/>
                    </div>
                </div>
            </form>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="toggleAssignInvModal" class="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" @click="assignInvitations" autofocus/>
            </template>
        </Dialog>
    </div>

    <Dialog header="Actualizar balance" v-model:visible="balanceModal" :style="{width: '50vw'}"
            :modal="true">
        <form action="">
            <div class="p-field">
                <div class="p-text-center" id="balanceForm">
                    <div class="p-text-center">
                      <label for="balanceType" class="p-d-block p-mb-2">Tipo</label>
                      <Dropdown id="balanceType" v-model="type" :options="types" optionLabel="name" optionValue="value" style="text-align: right;"/>
                    </div>
                    <div class="p-text-center">
                        <label for="updateBalance" class="p-d-block p-mb-2">Cantidad</label>
                        <InputNumber
                            ref="updateBalance"
                            id="updateBalance"
                            class="p-text-center"
                            v-model="balanceValue"
                            mode="decimal"
                            :useGrouping="false"
                            :minFractionDigits="2"
                            :min="1.00"/>
                    </div>
                    <div>
                      <label for="balanceDescription" class="p-d-block p-mb-2">Descripción</label>
                      <input-text id="balanceDescription" v-model="description" />
                    </div>
                </div>
                <div v-if="type === 'adjustment'" class="p-d-block p-mx-auto p-text-center p-mt-2" style="width: 50%;">
                    <Button class="p-button p-mr-1 p-text-lg-center p-text-bold" @click="sum = true; subs = false" :class="{ 'p-button-secondary' : !sum }"><span style="width: 10px;">+</span></Button>
                    <Button class="p-button p-ml-1 p-text-lg-center p-text-bold" @click="sum = false; subs = true" :class="{ 'p-button-secondary' : !subs}"><span style="width: 10px;">-</span></Button>
                </div>
            </div>
        </form>

        <template #footer>
            <div>
              <p class="p-text-center">{{ `Balance actual: ${ (selectedFoodie.balance_e2 / 100).toFixed(2) }€` }}</p>
            </div>
            <Button label="Cancelar" icon="pi pi-times" @click="balanceModal = false" class="p-button-text"/>
            <Button
                :disabled="type === 'withdrawal' && (balanceValue * 100) > selectedFoodie.balance_e2"
                label="Confirmar"
                icon="pi pi-check"
                @click="updateBalance()"
                autofocus/>
        </template>
    </Dialog>
    <!--  <div v-if="">-->

    <!--  </div>-->
    <Dialog header="Historial de balance" v-model:visible="balanceHistoricModal" :style="{width: '50vw'}"
            :modal="true">
        <DataTable
            dataKey="id"
            responsiveLayout="scroll"
            filterDisplay="menu"
            :rows="balanceHistoric.length"
            :lazy="true"
            :value="balanceHistoric"
            :rowHover="true"
            :loading="loadingHistoric"
        >
            <Column header="Tipo">
                <template #body="{data}">
                    {{ getType(data.type) }}
                </template>
            </Column>
            <Column field="description" header="Descripcion" />
            <Column field="created_at" header="Fecha">
              <template #body="{data}">
                {{ getDate(data.created_at) }}
              </template>
            </Column>
            <Column field="amount_e2" header="Valor agregado">
                <template #body="{data}">
                    {{ (data.amount_e2 / 100).toFixed(2) }}
                </template>
            </Column>
            <template #loading>
                Cargando movimientos.
            </template>
            <template #empty>
                No se encontraron movimientos.
            </template>

        </DataTable>

<!--        </DataTable>-->

        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="balanceHistoricModal = false" class="p-button-text"/>
            <Button label="Confirmar" icon="pi pi-check" @click="balanceHistoricModal = false" autofocus/>
        </template>
    </Dialog>
</template>

<script>
import { dateToLocalTimeAM } from "../../utils";

export default {
    data() {
        return {
            foodies: [],
            query: {
                'limit': 15,
                'paginate': true,
                'page': 1,
                'with': 'user',
                'sort_by': 'id,desc',
                'enabled': true,
                'deleted': false,
            },
            loading: false,
            searchDebounce: null,
            actionFoodie: null,
            actionMenuItems: [],
            displayAssignInvModal: false,
            invitationsQuantity: 1,
            selectedFoodie: {},
            types: [
              { name: 'Ajuste', value: 'adjustment'},
              { name: 'Retiro', value: 'withdrawal'},
              { name: 'Recompensa de referido', value: 'referral_reward'},
              // { label: 'Recompensa de colaboración', value: 'collaboration_reward'}
            ],
            type: 'adjustment',
            balanceModal: false,
            balanceHistoricModal: false,
            balanceHistoric: [],
            loadingHistoric: false,
            balanceValue: 1.00,
            description: '',
            sum: true,
            subs: false,
        }
    },
    methods: {
        getDate (date) {
          return dateToLocalTimeAM(date)
        },
        getType (type){
            switch(type) {
                case 'adjustment': return 'Ajuste'
                case 'withdrawal': return 'Retiro'
                case 'referral_reward': return 'Recompensa referido'
                case 'collaboration_reward': return 'Recompensa colaboración'
                default: return ''
            }
        },
        handleHistoric (data) {
            this.balanceHistoricModal = true
            this.loadingHistoric = true
            this.balanceHistoric = []
            this.$repository.foodies
                .balanceMovementsList(data.id, { limit: 0, paginate: false })
                .then((response) => {
                    this.balanceHistoric = response.data
                })
                .finally(() => this.loadingHistoric = false)
        },
        updateBalance () {
          const body = { type: this.type, amount_e2: this.balanceValue * 100, description: this.description }
          if (this.type === 'withdrawal' || this.subs) {
              body.amount_e2 = (this.balanceValue * 100) * -1
          }
          this.$repository.foodies
            .balanceMovements(this.selectedFoodie.id, body)
            .then(() => {
              this.$toast.add({severity: 'success', summary: 'se ha actualizado el balance', life: 2000});
              this.getFoodies()
            })
            .catch((err) => {
              const summary = err.response.data?.message
              let detail = err.response.data?.description
              if (err.response.data?.error_code === 'EB003') detail = 'Balance final inválido, no es posible tener menos de 0€'
              this.$toast.add({severity: 'error', summary, detail, life: 5000});
            })
            .finally(() => {
              this.balanceModal = false
            })
        },
        async getFoodies() {
            this.loading = true;

            try {
                const response = await this.$repository.foodies.list(this.query)
                this.foodies = response.data
            } catch (err) {

                let detail = 'Unable to get foodies'
                let summary = 'Error'

                if ('response' in err) {
                    summary = err.response.data?.message
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 1000});

            } finally {
                this.loading = false;
            }
        },
        async updateIgData () {
            this.$repository.users
                .updateIg(this.selectedUser.user_id)
                .then(() => {
                    this.$toast.add({
                      severity: 'info',
                      summary: `Actualización exitosa`,
                      life: 3000
                    })
                })
                .catch(() => {
                    this.$toast.add({
                      severity: 'error',
                      summary: `Ha ocurrido un error al actualizar la información.`,
                      life: 3000
                    })
                })
        },
        toggleAssignInvModal() {
            this.displayAssignInvModal = !this.displayAssignInvModal;
        },
        assignInvitations() {
            try {
                this.$repository.users.assignInvitations(this.actionFoodie.user_id, {
                    max_invitations: null,
                    max_consumable_invitations: this.invitationsQuantity,
                    invitation_expire_in_days: null
                })

                this.$toast.add({
                    severity: 'success',
                    summary: `${this.invitationsQuantity} invitaciones asignadas!`,
                    life: 1000
                });

                this.actionFoodie = null;
                this.invitationsQuantity = null;
                this.toggleAssignInvModal();

            } catch (err) {

                let detail = 'Unable to assign invitations'
                let summary = 'Error'

                if ('response' in err) {
                    detail = err.response.data?.description
                    summary = err.response.data?.message
                }

                this.$toast.add({severity: 'error', summary, detail, life: 1000});

            }
        },
        async enableUser() {
            if (this.actionFoodie.user.enabled) {
                this.$toast.add({
                    severity: 'info',
                    summary: `El usuario ya esta habilitado`,
                    life: 1000
                });

                return
            }

            try {
                const response = await this.$repository.users.enableUser(this.actionFoodie.user_id)

                this.$toast.add({
                    severity: 'success',
                    summary: `El usuario ha sido habilitado`,
                    life: 1000
                });

                this.updateFoodieUserById(this.actionFoodie.id, response.data)

            } catch (err) {

                let detail = null
                let summary = 'No se pudo habilitar el usuario'

                if ('response' in err) {
                    summary = err.response.data?.message
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 1000});

            }

        },
        async disableUser() {
            if (this.actionFoodie.user.enabled === false) {
                this.$toast.add({
                    severity: 'info',
                    summary: `El usuario ya esta deshabilitado`,
                    life: 1000
                });

                return
            }

            try {
                const response = await this.$repository.users.enableUser(this.actionFoodie.user_id, {
                    enabled: false
                })

                this.$toast.add({
                    severity: 'success',
                    summary: `El usuario ha sido deshabilitado`,
                    life: 1000
                });

                this.updateFoodieUserById(this.actionFoodie.id, response.data)

            } catch (err) {

                let code = null
                let detail = null
                let summary = 'No se pudo deshabilitar el usuario'

                if ('response' in err) {
                    code = err.response.data?.error_code
                    summary = `(${code}) ${err.response.data?.message}`
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 1000});

            }
        },
        toggleActionMenu(event, foodie) {
            this.actionFoodie = foodie;

            this.actionMenuItems = [
                {
                    label: 'Editar',
                    command: () => {
                        this.$router.push({name: 'foodies.update', params: {foodieId: foodie.id}})
                    }
                },
                {
                    label: 'Asignar invitaciones',
                    icon: 'pi pi-plus',
                    command: () => {
                        this.toggleAssignInvModal();
                    }
                }
            ];

            if (foodie.user.enabled) {
                this.actionMenuItems.push(
                    {
                        label: 'Deshabilitar usuario',
                        command: () => {
                            if (confirm('Confirmar deshabilitar, el usuario no podrá iniciar sesión y desaparecerá de algunos listados')) {
                                this.disableUser();
                            }
                        }
                    },
                );
            } else {
                this.actionMenuItems.push(
                    {
                        label: 'Habilitar usuario',
                        command: () => {
                            if (confirm('Confirmar habilitar, el usuario podrá iniciar sesión y sera visible en los listados')) {
                                this.enableUser();
                            }
                        }
                    },
                );
            }

            if (foodie.user.ig_validated_at === null) {
                this.actionMenuItems.push(
                    {
                        label: 'Validar instagram',
                        command: () => {
                            const validationCode = prompt('Confirmar validación, la cuenta de este foodie sera validada', 'Inserte aquí el código!');

                            if (validationCode) {
                                this.validateInstagram(validationCode);
                            }
                        }
                    },
                );
            }

            this.actionMenuItems.push(
                {
                    label: 'Actualizar información de instagram',
                    command: () => {
                      this.selectedUser = this.actionFoodie
                      this.updateIgData()
                    }
                  }
            );

          this.actionMenuItems.push(
              {
                label: `Marcar foodie como premium`,
                command: () => {
                  this.selectedUser = this.actionFoodie
                  this.markFoodieAsPremium()
                }
              }
          );

            if (foodie.deleted_at === null) {
                this.actionMenuItems.push(
                    {
                        label: 'Eliminar',
                        command: () => {
                            if (confirm('Seguro que desea eliminar este foodie?')) {
                                this.deleteFoodie();
                            }
                        }
                    },
                );
            } else {
                // already deleted
                this.actionMenuItems.push(
                    {
                        label: 'Restaurar',
                        command: () => {
                            if (confirm('Seguro que desea restaurar este foodie?')) {
                                this.restoreFoodie();
                            }
                        }
                    },
                );
            }

            if (foodie.user.ig_validated_at === null) {
                if (foodie.user.rejected === null) { // no rejected
                    this.actionMenuItems.push(
                        {
                            label: 'Rechazar',
                            command: () => {
                                const rejectedReason = prompt('Razón de rechazo (opcional)', null);

                                if (confirm('Seguro que desea rechazar este usuario?')) {
                                    this.reject(rejectedReason);
                                }
                            }
                        },
                    );
                }
            }

            this.actionMenuItems.push(
                {
                    label: 'Actualizar balance',
                        command: () => {
                            // const rejectedReason = prompt('Razón de rechazo (opcional)', null);
                            //
                            // if (confirm('Seguro que desea rechazar este usuario?')) {
                            //     this.reject(rejectedReason);
                            // }
                            this.balanceModal = true
                            this.selectedFoodie = foodie
                        }
                },
            );

            this.$refs.action_menu.toggle(event);
        },
        markFoodieAsPremium() {
          this.$repository.foodies.update(this.selectedUser.id, { is_premium: !this.selectedUser.is_premium })
            .then((res) => {
              this.$toast.add({
                severity: 'success',
                summary: `Se ha ${ res.is_premium ? 'desmarcado' : 'marcado' } el foodie como premium`,
                life: 2000
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: 'error',
                summary: 'Ha ocurrido un error',
                life: 2000
              });
            })
            .finally(() => {
              this.getFoodies()
            })
        },
        findFoodieById(id) {
            return this.foodies.data.filter((foodie) => foodie.id === id)[0] ?? null
        },
        updateFoodieUserById(foodieId, user) {
            return this.foodies.data.forEach((foodie, index) => {
                if (foodie.id === foodieId) {
                    const updated = {...foodie}
                    updated.user = user

                    this.foodies.data[index] = updated
                }
            });
        },
        resetQueryFilters() {
            this.query.enabled = true
            this.query.deleted = false
            this.query.rejected = null
            this.query.ig_validated = null
            this.query.search = ''
        },
        timestampToLocal(date) {
            return dateToLocalTimeAM(date);
        },
        async deleteFoodie() {
            if (this.actionFoodie.deleted_at !== null) {
                this.$toast.add({
                    severity: 'info',
                    summary: `El foodie ya ha sido eliminado`,
                    life: 3000
                });

                return;
            }

            try {
                await this.$repository.foodies.delete(this.actionFoodie.id);

                this.$toast.add({
                    severity: 'success',
                    summary: `Foodie eliminado`,
                    life: 2000
                });

                await this.getFoodies();

            } catch (err) {

                let code = null
                let detail = null
                let summary = 'No se pudo eliminar el foodie'

                if ('response' in err) {
                    code = err.response.data?.error_code
                    summary = `(${code}) ${err.response.data?.message}`
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 3000});
            }
        },
        async restoreFoodie() {
            if (this.actionFoodie.deleted_at === null) {
                this.$toast.add({
                    severity: 'info',
                    summary: `El foodie ya esta restaurado`,
                    life: 3000
                });

                return;
            }

            try {
                await this.$repository.foodies.restore(this.actionFoodie.id);

                this.$toast.add({
                    severity: 'success',
                    summary: `Foodie restaurado`,
                    life: 2000
                });

                await this.getFoodies();

            } catch (err) {

                let code = null
                let detail = null
                let summary = 'No se pudo restaurar el foodie'

                if ('response' in err) {
                    code = err.response.data?.error_code
                    summary = `(${code}) ${err.response.data?.message}`
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 3000});
            }
        },
        async reject(reason) {
            if (this.actionFoodie.user.rejected) {
                this.$toast.add({
                    severity: 'info',
                    summary: `El usuario ya esta rechazado`,
                    life: 3000
                });

                return;
            }

            try {
                await this.$repository.users.reject(this.actionFoodie.user_id, reason);

                this.$toast.add({
                    severity: 'success',
                    summary: `Usuario rechazado`,
                    life: 2000
                });

                await this.getFoodies();

            } catch (err) {

                let code = null
                let detail = null
                let summary = 'No se pudo rechazar el usuario'

                if ('response' in err) {
                    code = err.response.data?.error_code
                    summary = `(${code}) ${err.response.data?.message}`
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 3000});
            }
        },
        async validateInstagram(validationCode) {
            if (this.actionFoodie.user.ig_validated_at !== null) {
                this.$toast.add({
                    severity: 'info',
                    summary: `El foodie ya ha sido validado`,
                    life: 3000
                });

                return
            }

            try {
                const response = await this.$repository.users.validateInstagram(this.actionFoodie.user_id, {
                    validation_code: validationCode
                })

                this.$toast.add({
                    severity: 'success',
                    summary: `Instagram validado!`,
                    life: 2000
                });

                this.updateFoodieUserById(this.actionFoodie.id, response.data)

                this.getFoodies();

            } catch (err) {

                let code = null
                let detail = null
                let summary = 'No se pudo validar el foodie'

                if ('response' in err) {
                    code = err.response.data?.error_code
                    summary = `(${code}) ${err.response.data?.message}`
                    detail = err.response.data?.description
                }

                this.$toast.add({severity: 'error', summary, detail, life: 3000});
            }
        },
    },
    watch: {
        type(value) {
          if (value === 'adjustment')
            this.sum = true
            this.subs = false
        },
        'query.search'(newVal) {
            if (newVal === null) {
                return;
            } else if (newVal === '') {
                this.query.search = null;

                return;
            }

            if (this.searchDebounce) {
                clearTimeout(this.searchDebounce)
            }

            this.searchDebounce = setTimeout(() => {
                this.getFoodies();
            }, 600)
        },
        'query.enabled'() {
            this.getFoodies();
        },
        'query.deleted'() {
            this.getFoodies();
        },
        'query.rejected'() {
            this.getFoodies();
        },
        'query.ig_validated'() {
            this.getFoodies();
        },
        'query.page'() {
            this.getFoodies()
        }
    },
    mounted() {
        this.getFoodies();
    }
}
</script>

<style scoped>
.field-checkbox, .field-radiobutton {
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
#balanceForm{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-columns: minmax(175px, auto);
    column-gap: 10px;
}
#balanceForm div{
    width: 100%;
}
</style>
